<template>
  <div class="wrapper">
    <headBack>
      <template v-slot:title>
        <div class='title'>
           商品详情
        </div>
      </template>
    </headBack>
    <div class='header_cont'></div>
      <div class="banner_box">
        <div class='swiper_cont'>
          <van-swipe class="my-swipe" :autoplay="5000" indicator-color="white" :show-indicators="false">
            <van-swipe-item v-for="(item,index) in detailData.headPics">
              <img :src='item.picUrl' />
            </van-swipe-item>

          </van-swipe>
        </div>
      </div>
    <div class="detail_box">
        <div class="price">
           <div class="money"><span>¥</span>{{detailData.price}}</div>
           <div class="goods_name">
             {{detailData.name}}
           </div>
        </div>
        <div class="zp">
           <div class="item">
             <img src="https://static.yihu365.cn/img/h5Img/shop/zp_1.png" alt="">
             <span>正品保障</span>
           </div>
<!--          <div class="item">-->
<!--            <img src="https://static.yihu365.cn/img/h5Img/shop/zp_1.png" alt="">-->
<!--            <span>专属客服</span>-->
<!--          </div>-->
          <div class="item">
            <img src="https://static.yihu365.cn/img/h5Img/shop/zp_1.png" alt="">
            <span>先行赔付</span>
          </div>
          <div class="item">
            <img src="https://static.yihu365.cn/img/h5Img/shop/zp_1.png" alt="">
            <span>七天无理由退换</span>
          </div>
        </div>
       <div class="diz">
            <div class="tit">发货</div>
            <div class="wzhi">
                 <div>{{ logistics.deliveryCityDesc }}  <span>包邮</span></div>
                 <div class="time">48小时内发货</div>
            </div>
            <div class="nums">剩余 {{ inventoryInfo.inventoryNum }}</div>
       </div>
        <div class="pjia" @click="toEvaluate">
              <div class="p_tit">
                  <div>商品评价({{ evaluate.count }})</div>
                  <van-icon name="arrow" size="15"/>
              </div>
              <div class="p_detail" v-for="(item,index) in evaluate.pageData" v-show="index == 0">
                  <div class="user_box" >
                      <img class="tx" :src="item.userPicUrl" alt="" />
                      <div class="u_name">
                           <div>{{item.nickName}}</div>
                           <div>{{item.evaluateDateDesc}}</div>
                      </div>
                      <div class="pf">
                        <van-rate
                            v-model="item.star"
                            :size="16"
                            color="#00c291"
                            void-icon="star"
                            void-color="#eee"
                        />
                      </div>
                  </div>
                 <div class="info">{{item.content}}</div>
              </div>
        </div>
        <div class="goods_info">
            <div class="g_tits">商品详情</div>
             <img  v-for="(item,index) in detailData.detailPics" :src="item.picUrl" alt="">

        </div>
      <div class="goods_info">
        <div class="g_tits">商品说明</div>
        <div class="sm_box">

        </div>
      </div>
    </div>
    <div class="bottom_fixed">
      <div class="icon_box">
          <div class="kf" @click.stop='conext()'>
            <img src="https://static.yihu365.cn/img/h5Img/shop/fu2.png" alt="">
            <div>客服</div>
          </div>
          <div class="gow" @click="toCart">
              <div class="che_icon">
                <van-badge :content="cartInfo.goodsSum" @click="toCart">

                  <img src="https://static.yihu365.cn/img/h5Img/shop/cart_z.png" alt="">


                </van-badge>
              </div>

              <div>购物车</div>
          </div>
      </div>
      <div class="b_boxs">
          <button class="w_btn" @click="addCart">加入购物车</button>
          <button class="b_btn" @click="toOrderPage">立即购买</button>
      </div>
    </div>
  </div>
</template>
<script>
import {setWxShareDate} from '@/libs/utils.js'
import { onMounted, ref, computed } from "vue"
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import {Dialog, Toast} from 'vant'
import loginApi from '@axios/login'
import shopApi from '@axios/shop'
export default {
  name: "shopDetail",
  setup(){
    let router = useRouter()
    const store = useStore()
    const route = useRoute()
    const detailData = ref ('')
    const getDetail = () => {
      const params = {
          goodsId: route.query.goodsId
      }
      shopApi.getGoodsDetail(params).then((res)=> {
        detailData.value = res.data
        console.log('detail',res)
        setWxShareDate(detailData.value.name,detailData.value.iconUrl);
      })
    }
    getDetail();

    const getQualification = () => { //资质
      const params = {
        goodsId: route.query.goodsId
      }
      shopApi.getGoodsQualifications(params).then((res)=> {
        console.log('资质',res)
      })
    }
    getQualification();


    const logistics = ref('')
    const getlogistics = () => { //物流
      const params = {
        goodsId: route.query.goodsId
      }
      shopApi.getlogistics(params).then((res)=> {
        logistics.value = res.data
        console.log(res)
      })
    }
    getlogistics();

    const evaluate = ref('')
    const getEvaluateInfo = () => { //评价
      const params = {
        goodsId: route.query.goodsId,
        orderId:'',
        pageNum:1,
        pageSize:20,
        userId:store.state.uid
      }
      //getEvaluateInfo() evaluateSelect()
      shopApi.getEvaluateSelectForGoods(params).then((res)=> {
        evaluate.value =res.data;
        console.log('评价',res)
      })
    }
    getEvaluateInfo();


    const inventoryInfo =ref('')//库存
    const getInventoryInfos = () => {
      const params = {
        goodsId: route.query.goodsId
      }
      shopApi.getInventoryInfos(params).then((res)=> {
        console.log(res)
        inventoryInfo.value = res.data;
      })
    }
    getInventoryInfos();

    const cartInfo = ref('') //购物车
    const getCartNums = () => {
      const params = {
        userId : store.state.uid
      }
      shopApi.getCartInfos(params).then((res)=> {
        console.log('cart',res)
        cartInfo.value = res.data;
      })
    }
    getCartNums()

    //cart  add
    const addCart = (item) => {

      const params = {
        userId : store.state.uid,
        synchronization:[{
          goodsId : detailData.value.id,
          goodsNum : 1,
          type : 3 ,
          unitPrice : detailData.value.price,
          goodsName: detailData.value.name,
          iconUrl : detailData.value.iconUrl,
        }]

      }
      shopApi. setSynchronization(params).then((res)=> {
        console.log('Addcart',res)
        getCartNums();
      })
    }
    const  toCart = () => { //          购物车
      router.push({
        name: 'cart',
        query: {

        }
      })
    }
    //evaluate
    const toEvaluate =()=> {//商品评价
      router.push({
        name : 'evaluate',
        query : {
          goodsId:detailData.value.goodsId
        }
      })
    }

    //去订单
    const toOrderPage = () => {

      const obj = {
        'allNums':1,
        'total':detailData.value.price,
        'list':[
          {
            'goodsId':detailData.value.goodsId,
            'goodsNum':1,
            'goodsName':detailData.value.name,
            'iconUrl':detailData.value.iconUrl
          }
        ]
      }
      sessionStorage.setItem('orderData',JSON.stringify(obj));
      router.push({
        name : 'order',
        query:{
          type:'1'
        }
      })

    }
    const conext =()=> {
      window.location.href = 'tel://4008006996';
    }
    return {
      conext,
      detailData,
      logistics,
      inventoryInfo,
      cartInfo,
      toCart,
      addCart,
      toEvaluate,
      evaluate,
      toOrderPage
    }
  }

}
</script>

<style lang="scss"  scoped>
*{
  webkit-text-size-adjust: none;
}
   .wrapper{
     padding-bottom: 20px;
     .custom-indicator {
       position: absolute;
       right: 5px;
       bottom: 5px;
       padding: 2px 5px;
       font-size: 12px;
       background: rgba(0, 0, 0, 0.1);
     }
     .banner_box{
       .swiper_cont{
         min-height:500px;
         text-align: center;
         img{
           width: 70%;
         }
       }
     }

     .detail_box{
       background: #efeff4;
       box-sizing: border-box;
       padding:20px 20px 120px 20px;
       .price{
         background: #FFFFFF;
         border-radius: 8px 8px 0 0;
         box-sizing: border-box;
         padding:20px 24px;
         .money{
           color:#e94444;
           font-size: 46px;
           span{
             font-size: 34px;
           }
         }
         .goods_name{
           margin-top: 8px;
            font-size: 32px;
         }
       }
       .zp{
         padding:10px 20px;
         border-radius: 0 0 8px 8px;
         background: #faf9f9;
         display: flex;
         font-size: 22px;
         overflow: hidden;
         .item{
           display: flex;
           align-items: center;
           color:#00c291;
           white-space: nowrap;
           margin-right: 10px;
           img{
             width: 36px;
             height: 36px;
           }
         }
       }
     }
     .diz{
       font-size: 25px;
        margin-top: 20px;
        background: #FFFFFF;
        border-radius: 8px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        padding:20px;
       .tit{
         color:#888;
         margin-right: 20px;
       }
       .wzhi{
         font-size: 26px;
         flex-grow: 1;
       }
       .time{
         margin-top: 10px;
         font-size: 24px;
         color:#888;
       }
       .nums{
         color:#888;
         font-size: 24px;
       }
     }
     .pjia{
       margin-top: 20px;
       background: #FFFFFF;
       box-sizing: border-box;
       padding:20px;
       border-radius: 8px;
       font-size: 28px;
       .p_tit{
         display: flex;
         justify-content: space-between;
       }
       .p_detail{
         padding:10px;
         font-size: 24px;
         .user_box{
           display: flex;
           justify-content: space-between;
           .tx{
             width: 70px;
             height: 70px;
             margin-right: 20px;
             border-radius: 50%;
           }
           .u_name{
             flex-grow: 1;
           }
         }
         .info{
           margin-top: 10px;
           font-size: 24px;
         }
       }
     }
     .goods_info{
         margin-top: 20px;
         border-radius: 8px;
         background: #FFFFFF;
         box-sizing: border-box;
         padding:20px 0;
         font-size: 0;
        .g_tits{
          font-size: 30px;
          margin-left: 20px;
          margin-bottom: 20px;
        }
        img{
          width: 100%;
        }
     }
   }
   .bottom_fixed{
     width: 100%;
     height: 120px;
     position: fixed;
     left:0;
     bottom:0;
     border-top:1px solid #eee;
     background: #fff;
     display: flex;
     font-size: 25px;
     align-items: center;
     box-sizing: border-box;
     padding-left:40px;
     justify-content: space-between;
     .icon_box{
        display: flex;
     }
     .kf{
       color:#666;
      display: flex;
       flex-direction: column;
       align-items: center;
      margin-right: 30px;
        img{
          width: 46px;
          height: 46px;

        }
     }
     .gow{
       color:#666;
       .che_icon{
         ::v-deep(.van-badge--fixed){
           top:10px;
           right:4px;
         }
         ::v-deep(.van-badge){
           background-color:  #00c291;
           border-color:  #00c291;
         }
         width: 46px;
         height: 46px;
         position: relative;
         .c_num{
           font-size: 22px;
           height: 26px;
           line-height: 26px;
           background-color:  #f53b10;
           border-radius:14px;
           position: absolute;
           left: 60%;
           top: 2px;
           color:#fff;
           padding:3px 8px;
         }
       }
       display: flex;
       flex-direction: column;
       align-items: center;
       flex-grow: 1;
        margin-right: 40px;
       img{
         width: 50px;
         height: 50px;
       }
     }
     button{
       text-align: center;
       width: 220px;
       border:none;
       height:70px;
       color:#fff;
     }
     .b_boxs{
       display: flex;
     }
     .w_btn{
       font-size: 28px;
       background: linear-gradient(to top left,#efb336  0%, #EF7B1C 100%);
       white-space: nowrap;
       border-radius: 40px 0 0 40px;
     }
     .b_btn{
       font-size: 28px;
       background: linear-gradient(to bottom right, #25c011 0%,  #00c291 100%);
       border-radius:0 40px 40px 0;
       white-space: nowrap;
       margin-right: 20px;
     }
   }
</style>
