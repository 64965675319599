import { render } from "./detail.vue?vue&type=template&id=db5ab5e0&scoped=true"
import script from "./detail.vue?vue&type=script&lang=js"
export * from "./detail.vue?vue&type=script&lang=js"

import "./detail.vue?vue&type=style&index=0&id=db5ab5e0&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-db5ab5e0"
/* hot reload */
if (module.hot) {
  script.__hmrId = "db5ab5e0"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('db5ab5e0', script)) {
    api.reload('db5ab5e0', script)
  }
  
  module.hot.accept("./detail.vue?vue&type=template&id=db5ab5e0&scoped=true", () => {
    api.rerender('db5ab5e0', render)
  })

}

script.__file = "src/views/shop/detail/detail.vue"

export default script